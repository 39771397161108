<template>
  <div class="flex flex-col items-center">
    <NuxtLink to="/">
      <img
        src="../assets/img/mobileNavLogo.svg"
        class="hidden md:block w-[256px]"
        alt="underdogz logo"
      />
    </NuxtLink>
  </div>
  <div class="hidden md:block w-px h-[578px] bg-primary_900" />
  <div
    class="flex flex-col items-center gap-4 px-4 py-8 bg-neutral_950 rounded sm:border-2 border-primary_700 w-[340px]"
  >
    <form class="flex flex-col items-center gap-4" @submit.prevent="onLogin">
      <NuxtLink to="/">
        <img src="../assets/img/framedLogo.svg" class="block md:hidden" alt="logo of underdogz" />
      </NuxtLink>
      <p class="text-[28px] mt-2 md:mt-8 font-bold leading-[120%] text-neutral_50">
        {{ t('login') }}
      </p>
      <div class="flex items-center gap-6">
        <p class="text-[14px] text-center font-medium leading-[150%] text-neutral_50">
          {{ t('loginText') }}
        </p>
      </div>
      <NuxtLink :to="localePath('/register')">
        <ButtonComponent type="text" :cropped="true" :text="t('registerIn')" />
      </NuxtLink>
      <InputText
        v-model="email"
        size="medium"
        placeholder-text="Email"
        :error="v$.email.$error"
        :error-messages="v$.email.$errors"
      />
      <InputText
        v-model="password"
        size="medium"
        type="password"
        placeholder-text="Password"
        :error="v$.password.$error"
        :error-messages="v$.password.$errors"
      />

      <ButtonComponent
        class="hidden sm:flex mt-3"
        :is-loading="isLoading"
        :text="t('login')"
        size="md"
        :form-element="true"
      />
    </form>
    <NuxtLink :to="localePath('/forgot-password')">
      <ButtonComponent type="text" :text="t('forgotPasswordText')" />
    </NuxtLink>
    <p
      v-if="loginError"
      class="text-error_400 text-center font-medium text-[14px] leading-[150%] tracking-[-0.28px]"
    >
      {{ loginError }}
    </p>
    <div class="h-px w-full bg-primary_800" />
    <div class="flex flex-col items-center gap-3">
      <p
        class="text-[14px] font-medium leading-[150%] tracking-[-0.28px] text-neutral_50 text-center"
      >
        {{ t('googleLoginText') }}
        {{ t('googleAccount') }}
      </p>
      <div
        class="flex items-center justify-center w-10 h-10 rounded-full bg-neutral_400 cursor-pointer"
      >
        <ClientOnly>
          <ion-icon class="text-[24px] text-neutral_50" name="logo-google" @click="onGoogleLogin" />
        </ClientOnly>
      </div>
    </div>
  </div>
  <MobileBottomAppBar v-if="commonStore.isMobile">
    <ButtonComponent
      size="md"
      type="text"
      :is-loading="isLoading"
      :text="t('login')"
      @click="onLogin"
    />
  </MobileBottomAppBar>
</template>

<script lang="ts" setup>
import useVuelidate from '@vuelidate/core';
import { required, email as emailValidator, helpers } from '@vuelidate/validators';
import { useCommonStore } from '~/store/commonStore';
import { useDialogStore } from '~/store/dialogStore';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-vue-v3';
import { userRepository } from '~/repositories/userRepository';

definePageMeta({
  layout: 'initial-pages',
  middleware: ['noauth']
});

const router = useRouter();
const route = useRoute();

const dialogStore = useDialogStore();
const commonStore = useCommonStore();
const { t } = useI18n();
const localePath = useLocalePath();

const email = ref('');
const password = ref('');
const loginError = ref('');
const isLoading = ref(false);

const { $api } = useNuxtApp();
const userRepo = userRepository($api);

let getData: any;
let data: any;
if (import.meta.client) {
  // useVisitorData should only be called in the client
  const visitorData = useVisitorData({ extendedResult: true }, { immediate: false });
  data = visitorData.data;
  getData = visitorData.getData;
}

const rules = {
  email: {
    required: helpers.withMessage('Email je obavezan', required),
    emailValidator: helpers.withMessage('Email mora biti u ispravnom formatu', emailValidator)
  },
  password: { required: helpers.withMessage('Lozinka je obavezna', required) }
};

onMounted(() => {
  if (route.query.message && route.query.message === 'verificationSuccess') {
    dialogStore.createConfirmationDialog(
      t('verificationSuccessTitle'),
      t('verificationSuccessMessage'),
      t('ok'),
      '',
      () => {
        router.push(localePath('/login'));
      }
    );
  }
});

const v$ = useVuelidate(rules, { email, password });

const { login, googleLogin } = useLogin();

const onLogin = async () => {
  try {
    const isFormValid = await v$.value.$validate();
    if (isFormValid) {
      isLoading.value = true;
      const user = await login(email.value, password.value);
      isLoading.value = false;
      // SAVE FINGERPRINT DATA
      try {
        if (getData) {
          await getData();
          await userRepo.saveFpData(data.value);
        }
      } catch (fingerprintError) {
        console.error('Failed to get or save fingerprint data', fingerprintError);
        // Fingerprint failure won't block login
      }
      useTrackEvent('login', {
        method: 'Manually'
      });
      if (user) {
        loginError.value = '';
        user.lastLoginTime ? navigateTo('/') : navigateTo('/channels-subscribe');
      }
    }
  } catch (err) {
    isLoading.value = false;
    loginError.value = t('loginError');
  }
};

const onGoogleLogin = async () => {
  useTrackEvent('login', {
    method: 'Google'
  });
  await googleLogin();
};

useHead({
  title: t('login'),
  meta: [
    {
      name: 'description',
      content: t('meta.loginDescription')
    },
    {
      name: 'keywords',
      content: t('meta.keywords')
    }
  ]
});
</script>
